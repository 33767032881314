import { defineStore } from 'pinia';
import { useUserData } from './userData';

interface OpenCall { 
    author: number
    conception: string
    constraints: string
    deadline_date: string
    end_date: string
    file: string
    id: number
    location: string
    monetary: string
    name: string
    participants: string[]
    started_at: string
    task: string
    type: string
};  

export const useOpencall = defineStore('opencall', {
    state: () => { 
        return {
            openCallsListData: [] as OpenCall[],
            isPostsLoading: false,
            showMessageUpload: false,
            errorValidationMessage: false,
            message: {},
            idOpenCall: null,
            opencallsAuthor: {},
            membersData: {},
            opencallData: {},
        }
    },
    actions: {      
        async createOpenCall() {
            const store = useUserData();
            const formCreateOpenCall = document.querySelector('#formCreateOpenCall') as any;            
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch('/opencall/create/', {
                        method: 'POST',
                        data: new FormData(formCreateOpenCall),
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    if (response.data) {                        
                        this.showMessageUpload = true;
                        this.idOpenCall = response.data.id;
                        return;
                    };
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.errorValidationMessage = true;
                } finally {
                    this.isPostsLoading = false;
                }
            }  
        },
        async subsribeUserToOpenCall(id: number) {
            const store = useUserData();
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch(`/opencall/${id}/subscribe/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    return;
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.showErrorMessage({ error: "Возникла ошибка" });
                    
                } finally {
                    this.isPostsLoading = false;
                }
            }  
        },
        async acceptInvitationToOpenCall( id: number) {
            const store = useUserData();
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch(`/opencall/${id}/invite/accept/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    return;
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.showErrorMessage({ error: "Возникла ошибка" });
                } finally {
                    this.isPostsLoading = false;
                }
            }  
        },
        async declineInvitationToOpenCall( id: number) {
            const store = useUserData();
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch(`/opencall/${id}/invite/delete/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    return;
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.showErrorMessage({ error: "Возникла ошибка" });
                } finally {
                    this.isPostsLoading = false;
                }
            }  
        },
        async deleteOpenCall( id: number) {
            const store = useUserData();
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch(`/opencall/${id}/delete/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    return;
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.showErrorMessage({ error: "Возникла ошибка" });
                } finally {
                    this.isPostsLoading = false;
                }
            }  
        },
        async updateOpenCall(id: number) {
            const store = useUserData();
            const formUpdateOpenCall = document.querySelector('#formUpdateOpenCall') as any;
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch(`/opencall/${id}/update/`, {
                        method: 'PATCH',
                        data: new FormData(formUpdateOpenCall),
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    return;
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.showErrorMessage({ error: "Возникла ошибка" });
                } finally {
                    this.isPostsLoading = false;
                }
            }  
        },
        changeErrorValidationMessage(toggle: boolean) { 
            return this.errorValidationMessage = toggle
        },
        changeIsPostsLoading(toggle: boolean) { 
            return this.isPostsLoading = toggle
        },
        showErrorMessage(mes: any) { 
            this.message = { ...this.message , ...mes }
            setTimeout(() => this.message = {}, 3000)
            return            
        },
        async getOpencallsList() { 
            try {
                this.isPostsLoading = true;
                const response = await useApiFetch(`/opencall/list/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;',
                    },
                });
                if (response.data) {
                    this.openCallsListData = response.data.results;
                    return response.data.results;
                };
            } catch (error) {
                console.error(error);
            } finally { 
                this.isPostsLoading = false;
            }            
        },
        // async getOpencallsAuthor(id: number) { 
        //     try {
        //         this.isPostsLoading = true;
        //         const response = await useApiFetch(`/opencall/${id}/`, {
        //             method: 'GET',
        //             headers: {
        //                 'Content-Type': 'application/json;',
        //             },
        //         });
        //         if (response.data) {
        //             console.log("response.data", response.data);
                    
        //             this.opencallData = response.data;
        //             return this.opencallData;
        //         };
        //     } catch (error) {
        //         console.log("error getAuthorsData", error);
        //     } finally { 
        //         this.isPostsLoading = false;
        //     }             
        // },
        async getOpencallsData(id: number) { 
            try {
                this.isPostsLoading = true;
                const response = await useApiFetch(`/opencall/${id}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;',
                    },
                });
                if (response.data) {
                    this.opencallData = response.data;
                    this.opencallsAuthor = response.data.author;
                    return this.opencallData;
                };
            } catch (error) {
                console.error(error);
            } finally { 
                this.isPostsLoading = false;
            }              
        },
        async getOpencallsMembers(id: number) {            
            const store = useUserData();
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };
            if (storage) { 
                try {
                    this.isPostsLoading = true
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch(`/opencall/${id}/members/`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    if (response.data) {
                        this.membersData = response.data.results.filter((user: any) => user.is_accepted == true);
                        return this.membersData;
                    };
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                } finally {
                    this.isPostsLoading = false;
                }
            }             
        },
    }
})